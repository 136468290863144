<template>
   <div> 
      <!-- banner section Starts -->
      <section class="banner">
         <img style="   max-height: 470px; object-position: center;" :src="company_data.banner_image ?  company_data.banner_image : '../assets/images/homepage/banner-image.png'" alt="img" class="img-fluid banner-section-img">
         <div class="container">
            <div  :class="workAreaCount == 5 ? 'first-section onlyforcrousel allitemcarousel': 'first-section onlyforcrousel'">
               <carousel :margin="30" :nav="true" :navText="['&lt;','&gt;']" :rewind="false" :responsive="{0:{items:1, nav:true},768:{items:2,  nav:true},1280:{items:3, nav:true},1400:{items:4}}" class="owl-slider">
                     <!-- <template slot="prev"><span class="prev"><img src='/assets/images/prev-arrow.png'></span></template> -->
                     <div class="item" v-if="company_data.work_area.includes('Home')">
                        <div class="custome_card card1 for_wrap">
                           <router-link :to="{ name: 'work_from_home' }"> 
                              <div class="px_8  py_8 card1-icon">
                                 <img v-if="company_data.work_area_details.Home && company_data.work_area_details.Home.icon" :src="company_data.work_area_details.Home.icon"  alt="img">
                                 <img v-else src="../assets/images/homepage/home.png"  alt="img">
                              </div>
                              <h5 v-if="company_data.work_area_details.Home.title.length>23" v-tooltip="company_data.work_area_details.Home.title" class="font_bold font_size_22 mb_14 text_black"> {{company_data.work_area_details.Home && company_data.work_area_details.Home.title ?  company_data.work_area_details.Home.title : 'Working from home?' }}</h5>
                              <h5 v-else class="font_bold font_size_22 mb_14 text_black"> {{company_data.work_area_details.Home && company_data.work_area_details.Home.title ?  company_data.work_area_details.Home.title : 'Working from home?' }}</h5>
                              <p class="text_balticsea font_size_18 mb_0 ">
                                 {{company_data.work_area_details.Home && company_data.work_area_details.Home.description ?  company_data.work_area_details.Home.description : 'Discover the best ways to bring ergo into your home, boost your productivity and feel great!' }}                              
                              </p>
                           </router-link>
                        </div>
                     </div>
                     <div class="item" v-if="company_data.work_area.includes('Office')">
                        <div class="custome_card card1 for_wrap">
                           <router-link :to="{ name: 'work_from_office' }">
                              <div class="px_8  py_8 card1-icon">
                                 <img v-if="company_data.work_area_details.Office && company_data.work_area_details.Office.icon" :src="company_data.work_area_details.Office.icon" alt="img">
                                 <img v-else src="../assets/images/homepage/office.png"  alt="img">
                              </div>
                              <h5 v-if="company_data.work_area_details.Office.title.length>23" v-tooltip="company_data.work_area_details.Office.title" class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Office && company_data.work_area_details.Office.title ?  company_data.work_area_details.Office.title : 'Working from office?' }}</h5>
                              
                              <h5 v-else class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Office && company_data.work_area_details.Office.title ?  company_data.work_area_details.Office.title : 'Working from office?' }}</h5>
                              <p class="text_balticsea font_size_18 mb_0 ">
                                 {{company_data.work_area_details.Office && company_data.work_area_details.Office.description ?  company_data.work_area_details.Office.description : 'Discover your ideal setup, learn the best ergo practices and be on your A game every single day. ' }}                              
                              </p>
                           </router-link>
                        </div>
                     </div>
                     <div class="item" v-if="company_data.work_area.includes('Anywhere')">
                        <div class="custome_card card1 for_wrap">
                           <router-link :to="{ name: 'work_from_anywhere' }">
                              <div class="px_8  py_8 card1-icon">
                                 <img v-if="company_data.work_area_details.Anywhere && company_data.work_area_details.Anywhere.icon" :src="company_data.work_area_details.Anywhere.icon" alt="img">
                                 <img v-else src="../assets/images/homepage/anywhere.png"  alt="img">
                              </div>
                              <h5 v-if="company_data.work_area_details.Anywhere.title.length>23" v-tooltip="company_data.work_area_details.Anywhere.title" class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Anywhere && company_data.work_area_details.Anywhere.title ?  company_data.work_area_details.Anywhere.title : 'Working from anywhere?' }}</h5>
                              <h5 v-else class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Anywhere && company_data.work_area_details.Anywhere.title ?  company_data.work_area_details.Anywhere.title : 'Working from anywhere?' }}</h5>
                              <p class="text_balticsea font_size_18 mb_0 ">
                                 {{company_data.work_area_details.Anywhere && company_data.work_area_details.Anywhere.description ?  company_data.work_area_details.Anywhere.description : 'Hello Hybrid.  Shuttling back and forth between the office and home? We’ve got you. '}}
                              </p>
                           </router-link>
                        </div>
                     </div>
                     <div class="item" v-if="company_data.work_area.includes('Lab')">
                        <div class="custome_card card1 for_wrap">
                           <router-link :to="{ name: 'work_from_lab' }">
                              <div class="px_8  py_8 card1-icon">
                                 <img v-if="company_data.work_area_details.Lab && company_data.work_area_details.Lab.icon " :src="company_data.work_area_details.Lab.icon" alt="img">
                                 <img v-else src="../assets/images/homepage/lab.png"  alt="img">
                              </div>
                              <h5 v-if="company_data.work_area_details.Lab.title.length>23" v-tooltip="company_data.work_area_details.Lab.title" class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Lab && company_data.work_area_details.Lab.title ?  company_data.work_area_details.Lab.title : 'Working from lab?' }}</h5>
                              <h5 v-else class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Lab && company_data.work_area_details.Lab.title ?  company_data.work_area_details.Lab.title : 'Working from lab?' }}</h5>
                              <p class="text_balticsea font_size_18 mb_0 ">
                                 {{company_data.work_area_details.Lab && company_data.work_area_details.Lab.description ?  company_data.work_area_details.Lab.description : 'Learn how to navigate the variety of working environments inherent in labs to feel great.'}}
                              </p>
                           </router-link>
                        </div>
                     </div>                     
                     <div class="item" v-if="company_data.work_area.includes('Travel')">
                        <div class="custome_card card1 for_wrap">
                           <router-link :to="{ name: 'travel_for_work' }">
                              <div class="px_8  py_8 card1-icon">
                                 <img v-if="company_data.work_area_details.Travel && company_data.work_area_details.Travel.icon " :src="company_data.work_area_details.Travel.icon" alt="img">
                                 <img v-else src="../assets/images/homepage/lab.png"  alt="img">
                              </div>
                              <h5 v-if="company_data.work_area_details.Travel.title.length>23" v-tooltip="company_data.work_area_details.Travel.title" class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Travel && company_data.work_area_details.Travel.title ?  company_data.work_area_details.Travel.title : 'Working from lab?' }}</h5>
                              <h5 v-else class="font_bold font_size_22 mb_14 text_black">{{company_data.work_area_details.Travel && company_data.work_area_details.Travel.title ?  company_data.work_area_details.Travel.title : 'Working from lab?' }}</h5>
                              <p class="text_balticsea font_size_18 mb_0 ">
                                 {{company_data.work_area_details.Travel && company_data.work_area_details.Travel.description ?  company_data.work_area_details.Travel.description : 'Learn how to navigate the variety of working environments inherent in labs to feel great.'}}
                              </p>
                           </router-link>
                        </div>
                     </div>
               </carousel>            
            </div>
         </div>
      </section>
      <div class="text-center down_to_section newarrow" v-show="company_code">
         <a   v-scroll-to="{
            el: '#take_an_ergonimics',
            duration: 500,
            easing: 'linear',
            offset: -350,}">
            <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
               <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
                  <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                  <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
               </g>
            </svg>
         </a>
      </div>
      <!-- Banner section Ends -->
      <section id="take_an_ergonimics" class="take_an_ergonomic" v-show="company_code">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-9 ">
                  <div class="row">
                     <div class="col-md-12 col-lg-6 pe-lg-0 order-2 order-lg-1">
                        <div class="bg_orange left-section pt_36 pb_36 pl_78 pr_78 text-center">
                           <h4 class="heading_4 font_bold mb_10 text-white mx-auto">Take an ergonomic
                              self-assessment</h4>
                           <p class="text-white p_25 mx-auto">Take a self-assessment today and see how ergo can help you feel
                              great, enjoy more energy and supercharge your productivity. </p>
                           <div class="mt_14">
                              <router-link :to="{ name: 'self_assessment_signup' }" class="btn btn_secondary font_size_20">Take a Self-Assessment</router-link>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-12 col-lg-6 ps-lg-0 align-self-center order-1 order-lg-2 ">
                        <div class="right-section bg-white">
                           <router-link :to="{ name: 'self_assessment_signup' }"><img src="../assets/images/ergonomic-image.png" alt="Image"></router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> 
      <section class="how_else_ergo ergo_effect" >
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-9">
                  <div class="sub-heading">
                     <h4 class="heading_4 text_black font_bold">How else can ergo help you today?</h4>
                  </div>
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'setup_guidline' }">
                              <img src="../assets/images/homepage/how-1.png" alt="img" class="mb_34">
                              <div class="pl_12 card_content">
                                 <h3 class="d-block text_primary mb_6 font_size_22">Setup Guidelines</h3>
                                 <h5 class="font_bold font_size_26 mb_10 text_woodsmoke">Reduce Eye Strain</h5>
                                 <p class="font_size_18 mb_24 text_balticsea">
                                    Struggling with eye fatigue and eyestrain?  Let us help! 
                                 </p>
                                 <p class="text_concord font_size_14">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>                        
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'work_better' }">
                              <img src="../assets/images/homepage/how-2.png" alt="img" class="mb_34">
                              <div class="pl_12 card_content">
                                 <h3 class="d-block text_primary mb_6 font_size_22">Work Better</h3>
                                 <h5 class="font_bold font_size_26 mb_10 text_woodsmoke">Get More Energy</h5>
                                 <p class="font_size_18 mb_24 text_balticsea">
                                    Feeling sluggish these days?  Let’s put some pep in your step. 
                                 </p>
                                 <p class="text_concord font_size_14">
                                    5 minute journey
                                 </p>
                              </div> 
                           </router-link>                     
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'ergo_essentials' }">
                              <img src="../assets/images/homepage/how-3.png" alt="img" class="mb_34">
                              <div class="pl_12 card_content">
                                 <h3 class="d-block text_primary mb_6 font_size_22">Ergo Essentials</h3>
                                 <h5 class="font_bold font_size_26 mb_10 text_woodsmoke">Posture Perfect</h5>
                                 <p class="font_size_18 mb_24 text_balticsea">
                                    No matter where you work take your perfect posture with you.
                                 </p>
                                 <p class="text_concord font_size_14">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import carousel from "vue-owl-carousel"
export default {
   name: 'home',
   components: { carousel },
   data() {
      return {
         workAreaCount: 0,
         company_data : {
            banner_image: require('@/assets/images/homepage/banner-image.png'),
            logo: require('@/assets/images/logo.png'),
            work_area:null,
            work_area_details:{
               Office:{
                  icon: require('@/assets/images/homepage/office.png'),
                  title:'Working from office?',
                  description:'Discover your ideal setup, learn the best ergo practices and be on your A game every single day.',
               },
               Home:{
                  icon:require('@/assets/images/homepage/home.png'),
                  title:'Working from home?',
                  description:'Discover the best ways to bring ergo into your home, boost your productivity and feel great!',
               },
               Anywhere:{
                  icon:require('@/assets/images/homepage/anywhere.png'),
                  title:'Working from anywhere?',
                  description:'Hello Hybrid.  Shuttling back and forth between the office and home? We’ve got you.',
               },
               Lab:{
                  icon:require('@/assets/images/homepage/lab.png'),
                  title:'Working from lab?',
                  description:'Learn how to navigate the variety of working environments inherent in labs to feel great.',
               },
            },
         },
         base_url : null,
         company_code: true,
      };
   },
   created() {
    this.$parent.$refs.fullpageloader.is_full_page_loader = true;
   },
   beforeMount() {
      this.base_url = process.env.VUE_APP_API_URL;
      // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
      let data = this.$store.getters["auth/authUser"];
      let company_data = data.client;
      if(company_data.self_assessment_button_status == 0){
         this.company_code = false;
      }
      //This is used for menu access
      if (company_data.site_menu_details != undefined) {
         if(company_data.site_menu_details.Home!= undefined && company_data.site_menu_details.Home.status==0){
             this.$router.push({ name: "404" });
         }
      }
      if(company_data == null){
         company_data = [];
      }
      if(company_data.length == 0 || company_data.length === 'undefined'){
         this.company_data.work_area = '["Office","Home","Anywhere","Lab"]';
         this.workAreaCount = 4;
      }else{
         this.company_data.banner_image = company_data.banner_image;
         this.company_data.logo = company_data.logo;
         this.company_data.work_area = company_data.work_area;
         this.company_data.site_menu_details = company_data.site_menu_details;
         if(company_data.work_from_area != undefined && company_data.work_from_area.length == undefined){
            this.company_data.work_area_details = company_data.work_from_area;
            this.workAreaCount = Object.keys(this.company_data.work_area_details).length;
         }
      }   
   },
   mounted(){
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      setTimeout(() => {
         this.$parent.$refs.fullpageloader.is_full_page_loader = false;
      }, 500);      
   }
}

</script>
